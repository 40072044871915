.single-news {
  h2 {
    color: #0078c6;
    margin-bottom: 5px;
    padding-bottom: 0;
  }

  .single-post-image {
    width: 100%;
    max-width: 400px;
  }

  .news-body {
    border-bottom: none;
  }
}
