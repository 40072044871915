.preview-mode {
  button {
    background: transparent;
    border: 1px solid #093a41;
  }
  button:hover {
    background: #093a41;
    color: #fff;
  }
}
