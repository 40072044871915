.footer {
  @media only screen and (max-width: 600px) {
    display: none;
  }
  background: #ebebeb;
  padding-top: 90px;
  padding-bottom: 140px;
  position: relative;
  /*   border-top: 1px solid #cccccc; */
  min-height: 516.27px;

  .button-backTop {
    position: absolute;
    left: 48%;
    top: -38px;

    img {
      cursor: pointer;
    }

    @media only screen and (max-width: 993px) {
      left: 45%;
    }
  }

  a {
    /*  color: #555555; */
    text-decoration: none;
    font-size: 12px;
    font-family: 'helvetica';

    &:hover {
      opacity: 0.9;
    }
  }

  p {
    line-height: 1.43em;
    /*  color: #555555; */
  }

  .teaser {
    display: table-cell;
    height: 130px;
    vertical-align: middle;

    img {
      height: 120px;
      object-fit: contain;
    }
  }
  .grid {
    display: grid;
    padding: 0 90px;
    grid-template-columns: repeat(5, 1fr);
    gap: 20px;
    align-items: baseline;

    @media only screen and (max-width: 1024px) {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      padding: 0 70px;
    }

    img {
      width: 100%;
      margin-bottom: 10px;
    }
  }
}
