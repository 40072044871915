.modal-overlay {
  position: fixed; /* Use fixed positioning */
  top: 0;
  left: 0;
  width: 100%; /* Full width */
  height: 100vh; /* Full height of the viewport */
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  display: flex; /* Use flexbox to center the modalData */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  z-index: 1050; /* Ensure it's above other content */
}

.modalData {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  text-align: center;

  button:not(:last-of-type) {
    background: #093a41;
    width: 120px;
  }

  button:not(:last-of-type):hover {
    background: #0c4b55;
    width: 120px;
  }
}
