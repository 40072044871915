.footer-companies {
  padding: 60px 0;

  img {
    width: 100%;
    height: 150px;
    object-fit: contain;
  }

  .heading {
  }

  .footer-company-grid {
    margin-top: 40px;
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;

    .footer-company-form {
      border: 1px solid rgb(202, 199, 199);
      padding: 15px;
      width: 100%;
    }
  }

  .form-group {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 10px;

    textarea {
      min-height: 200px;
      max-width: 100%;
    }

    textarea,
    input {
      padding: 5px 10px;
      border: 1px solid rgb(161, 159, 159);

      color: rgb(53, 51, 51);
      font-size: 15px;
    }

    /*   input {
      max-width: min-content;
    } */
  }
}
