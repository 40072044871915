.color-dark {
  color: #1e1e1e;
}

.color-white {
  color: #fff;
}
.video-modal {
  position: fixed;
  background: #000000e0;
  min-height: 100vh;
  display: flex;
  width: 100%;
  align-items: center;
  z-index: 999999999;
  transform: scale(0.3);
  padding: 20px;
  opacity: 0;
  transition: all 0.2s;
  pointer-events: none;
  top: 0;
}

.overlay-background.video-minimized {
  position: fixed !important;
  background-color: transparent;
  top: 10%;
  z-index: 99999999999999999999999;
}

.draggable-handle {
  cursor: grab;
  /* Add more styling as needed */
}

.video-modal.active {
  transform: scale(1);
  opacity: 1;
  pointer-events: initial;
}

.closeModal {
  position: absolute;
  top: 20px;
  right: 20px;
  color: #eeeeeebd;
  font-weight: 500;
  font-size: 18px;
  cursor: pointer;
  z-index: 999999;
}
.custom-video-player {
  position: relative;
  max-width: 70%;
  margin: 0 auto;
  top: -8px;

  @media only screen and (max-width: 700px) {
    max-width: 95%;
  }
}

.custom-video-player.minimized {
  max-width: 350px;
  transition: none;
}

.video-container {
  width: 100%;
  display: block;
}

.video {
  width: 100%;
  transition: all 0.3s;
}

.portrait {
  max-width: 100%;
  max-height: 750px; /* adjust height automatically */
}

.landscape {
  width: 100%;
}

.left-arrow {
  position: absolute;
  top: 45%;
  left: -40px;
  cursor: pointer;
}

.right-arrow {
  position: absolute;
  top: 45%;
  right: -40px;
  cursor: pointer;
}

.controls {
  display: flex;
  flex-direction: column;
  background-color: #1e1e1e;
  padding: 10px;
  border-radius: 10px;
  max-width: 500px;
  margin: 0 auto;
  margin-bottom: 5px;
}

.bottom-controls {
}

.main-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.volume-control {
  display: flex;
  align-items: center;
}

.volume-bar {
  width: 60px;
  height: 3px;
  background-color: #0a0a0a;
  margin: 0 10px;
  cursor: pointer;
  position: relative;
}
i {
  color: #fff;
}

.volume-progress {
  z-index: 999;
  height: 3px;

  background-color: #fff;
}

.sound-icon {
  background: none;
  border: none;
  cursor: pointer;
}

.muted {
  filter: grayscale(100%);
}

.play-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0 20px;
  color: #fff;
}

.misc-controls button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0 10px;
}

.progress-bar-container {
  display: flex;
  align-items: center;
  padding-left: 5px;
  padding-right: 4px;
}

.video-time,
.current-time {
  color: #918e8e;
  font-size: 12px;
}

.progress-bar-wrapper {
  flex-grow: 1;
  margin: 0 10px;
  background: #0a0a0a;
  cursor: pointer;
}

.progress-bar {
  width: 100%;
  height: 3px;
  border-radius: 2.5px;
  cursor: pointer;
  background-color: white;
  color: #333;
}

.controls button:hover i {
  opacity: 0.8;
}

.overlay-main {
  position: relative;
}

.overlay-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
}

.speed-wrapper {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  bottom: 13%;
  background: #1e2126;
  left: 55%;
  transition: all 0.3s;
  border-radius: 4px;

  ul {
    margin: 0;
    padding: 0;
  }
  li {
    cursor: pointer;
    padding: 5px 20px;
    letter-spacing: 1.2px;
    color: #ffffffc5;
  }
  li:hover {
    background: #1e1e1e;
  }
}

.overlay-background:hover ~ .video-options {
  visibility: visible;
}

.speed-wrapper.active {
  visibility: visible;
  opacity: 1;
  display: block;
}

.speed-menu {
  font-size: 12px;
  color: #fff;
  width: 200px;

  span {
    padding: 10px;
  }
}

.speed-icon {
  border-top: 1px dotted #eee;
  border-bottom: 1px dotted #eee;
  color: #eee;
  font-size: 14px;
}

.progress-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.line {
  width: 100%;
  height: 5px;
  display: block;
  background: #3f3f3f;
}

.speed-progress-bar {
  margin: 10px;
  width: 90%;
  background: #686868;
  height: 5px;
  cursor: pointer;
}
.speed-progress {
  background: #0087d5;
  height: 5px;
}

.speed-menu-button:hover + .speed-wrapper {
  display: block;
}
