.about-page {
  p {
    font-size: 16px;
  }

  h2 {
    margin-top: 60px;

    @media only screen and (max-width: 993px) {
      margin-top: 10px;
    }
  }

  span {
    font-size: 15px;
    letter-spacing: 0;
    color: gray;
  }
}
