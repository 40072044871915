/* Navbar.module.scss */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 993px) {
    display: none;
  }
}

.navbar-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.navbar-item {
  margin-right: 23px;
}

.navbar-item:last-child {
  margin-right: 0;
}

.navbar-link {
  text-decoration: none;
  color: #495f69;
  font-size: 16px;
  letter-spacing: 2px;
}

.navbar-link:hover {
  opacity: 0.8;
}
