.search {
  margin-top: 74px;
  margin-bottom: 80px;

  form {
    max-width: 671.6px;
    margin: 0 auto;
  }
  img {
    position: absolute;
    right: 11px;
    top: 13px;
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: 0.5em 50%;
    width: 19px;
    cursor: pointer;
  }

  input[type='search']::-ms-clear {
    display: none;
  }
  input[type='search']::-webkit-search-cancel-button {
    display: none;
  }
  input {
    border: #cccccc 1px solid;
    border-radius: 4px;
    color: #7d939f;
    font-size: 1.2em;
    font-weight: normal;
    height: 53.8px;
    letter-spacing: 2px;
    outline: 0;
    padding: 0.7em 1.7em 0.7em 1em !important;
    width: 100%;
  }
  input:focus {
    color: #7d939f;
    border-color: #66afe9;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset,
      0 0 8px rgba(102, 175, 233, 0.6);
  }
}
