.persons {
  h2 {
    margin-top: 60px;

    @media only screen and (max-width: 940px) {
      margin-top: initial;
    }
  }
  .img-container {
    max-width: 100%;

    h3 {
      font-weight: 400;
    }
  }
  .grid-5 {
    grid-template-columns: repeat(6, 150px);

    .img-container {
      width: 150px;
      height: 204px;

      @media only screen and (max-width: 600px) {
        width: 100%;
        min-height: 300px;
      }

      h5 {
        font-size: 18px;
      }

      img {
        height: 100%;
        object-fit: cover;
      }
    }

    @media only screen and (max-width: 940px) {
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    }
  }
}
