.tab-content {
  display: flex;
  align-items: center;
  margin: 25px 0;
  min-height: 110px;

  .spinner-mobile .spinner-border {
    width: 1.1rem;
    height: 1.1rem;
  }

  .grid-document-style {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    padding: 0 25px;

    @media only screen and (max-width: 700px) {
      grid-template-columns: repeat(2, 1fr);
      padding: 0;
    }
  }

  .counter-date {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 5px;

    svg {
      margin-bottom: 3px;
      width: 21px;
      height: 21px;
    }

    /*  i {
      position: relative;
      top: 5px;
      color: #093a41;
    } */

    span:first-child {
      display: flex;
      align-items: flex-end;
    }

    span {
      display: flex;
      font-weight: 600;
      margin-left: 6px;
    }
  }

  p {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .audio-container {
    display: flex;
    /* align-items: flex-start; */
    gap: 5px;
    border: 1px solid #eee;
    padding: 15px;

    div {
      cursor: pointer;
    }

    span {
      margin-top: 1px;
      font-size: 13px;
    }
  }

  .audio-container i {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #0087d5;
    font-size: 30px;
  }
  ul {
    margin-bottom: 0;
  }
}

.tab-content.documents {
  ul li {
    display: flex;
    align-items: center;

    a {
      margin-left: 5px;
      width: 100%;
      font-size: 13px;
    }
  }
}

.tab-content .realeses {
  a {
    display: inline-block !important;
  }
}

.custom-content {
  a {
    display: inline-block !important;
  }
}
.tab-content .image-gallery-lightbox {
  padding: 0 25px;
}

.videos {
  p:first-child {
    margin-left: 25px;
  }
}
